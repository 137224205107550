import React from 'react'
// import Card from "./DunamisTravels/Card"
import './Courses.css'
// import {useNavigate} from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const EarningWays = () => {

  return (
    <>
      <Helmet>
        <title>EarningWays - SUPERJCYBS</title>
        <meta name="description" content='Lets give you diverse options for earning passive income online' />
        <meta property="og:title" content='EarningWays - SUPERJCYBS' />
        <meta property="og:description" content='Lets give you diverse options for earning passive income online' />
        <meta property="og:url" content='https://superjcybs.com/earningways' />
        <link rel='canonical' href='https://superjcybs.com/earningways' />
      </Helmet>
      <section className="courses">
        <h1 className='text-center'>Earning Ways (DEVELOP YOURSELF ALSO)</h1>
        <p className='px-5 text-center fw-bold'>Here, it isn't basically all about getting fast money, because some of them
          are training suggestions. For examples, we have a section for people who are
          looking for certified platforms/websites to learn professional courses.
        </p>
        <p> <a href="https://t.me/superjcybs" target="blank">JOIN OUR TELEGRAM CHANNEL</a></p>
        <br />
        <a href="https://bit.ly/superjcybsonppl" className='d-flex justify-content-center'>
          <img src="http://cashsuperstar.com/ppl/images/makemoneynow2_336x280_2014.jpg" alt="Want To Increase Your Online Income? Then Get This System..." />
        </a>

        <br />

        <h2 id='airdrops'>HOT AIRDROPS AND TOKENS (CRYPTO)</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__info">
              <h5>FREE MINING ON TELEGRAM </h5>
              <p> You need to have telegram in order to partake. Click on that which you like to join </p>
              <a href='https://t.me/herewalletbot/app?startapp=776841' target='blank' className="btn btn-success m-1 p-1">HOT COIN</a><br />
              <a href='https://t.me/XBlastAppBot/App?startapp=EZ7HTD' target='blank' className="btn btn-secondary m-1 p-1">xBLAST</a>
              <a href='https://t.me/dotcoin_bot?start=r_808745748' target='blank' className="btn btn-danger m-1 p-1">DOTCOIN</a>
              <a href='https://t.me/tapswap_bot?start=r_808745748' target='blank' className="btn btn-warning m-1 p-1">TAPSWAP</a>
              <a href='https://t.me/DailyWalletBot?start=808745748' target='blank' className="btn btn-warning m-1 p-1">DAILY WALLET</a>
              <a href='https://t.me/catizenbot/gameapp?startapp=rp_29681868' target='blank' className="btn btn-warning m-1 p-1">CATIZEN</a>
              <a href='https://t.me/blum/app?startapp=ref_Nu7c6DsBDB' target='blank' className="btn btn-warning m-1 p-1">BLUM</a>
              <a href='https://t.me/rocky_rabbit_bot/play?startapp=frId808745748' target='blank' className="btn btn-warning m-1 p-1">ROCKY RABBIT</a>
              <a href='https://t.me/whitebunnywtf_bot?start=ref66d1b14a5d7bfcf17701c22e' target='blank' className="btn btn-warning m-1 p-1">WHITE BUNNY</a>
              <a href='https://t.me/tonextra.com/ref/3FfckbhP' target='blank' className="btn btn-warning m-1 p-1">TON EXTRA</a>
              <a href='https://t.me/Agent301Bot/app?startapp=onetime808745748' target='blank' className="btn btn-warning m-1 p-1">AGENT 301</a>
              <a href='https://t.me/boinker_bot/boinkapp?startapp=boink808745748' target='blank' className="btn btn-warning m-1 p-1">BOINKER</a>
              <a href='https://t.me/theHoldCoinBot/app?startapp=ref_LR7tZcEI' target='blank' className="btn btn-warning m-1 p-1">HOLDCOIN</a>
              <a href='https://t.me/bountybay_bot/deals?startapp=invite-30347b14' target='blank' className="btn btn-warning m-1 p-1">BOUNTY BAY</a>
              <a href='https://t.me/money_dogs_bot/money_dogs?startapp=xneEfQuj' target='blank' className="btn btn-warning m-1 p-1">MONEY DOGS</a>
              <a href='https://t.me/TondropEarnBot/app?startapp=66d27125dac61ea9b1c0fcbb' target='blank' className="btn btn-warning m-1 p-1">TONDROP</a>
              <a href='https://t.me/MatchQuestBot/start?startapp=c9c682621c5a1d796310cb63c839952b' target='blank' className="btn btn-warning m-1 p-1">MATCH QUEST</a>
              <a href='https://t.me/OLYCoinBot/OLYCoin?startapp=FS2UMBC' target='blank' className="btn btn-warning m-1 p-1">OLYCOIN</a>
              <a href='https://t.me/obix_bot?start=808745748' target='blank' className="btn btn-warning m-1 p-1">OBIX</a>
              <a href='https://t.me/avagoldcoin_bot?start=0e63d0cc20a10c9cc796' target='blank' className="btn btn-warning m-1 p-1">AVACOIN</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__info">
              <h5>FREE MINING ON TELEGRAM 2</h5>
              <a href='https://t.me/memefi_coin_bot?start=r_c8f243a914' target='blank' className="btn btn-warning m-1 p-1">MEMEFI COIN</a>
              <a href='https://t.me/TimeFarmCryptoBot?start=LyGfF2lq79iNJHll' target='blank' className="btn btn-warning m-1 p-1">TIMEFARM</a>
              <a href='https://kococoin.com/?ref=c3d23b902f' target='blank' className="btn btn-warning m-1 p-1">KOCOCOIN</a>
              <a href='https://shibaai.club/signup?my=2323896' target='blank' className="btn btn-info m-1 p-1" >SHIBAI AIRDROP</a>
              <a href='https://t.me/BFDCoin_bot/BFDCoin?startapp=808745748' target='blank' className="btn btn-info m-1 p-1" >BFDCOIN</a>
              <a href='https://freebitco.in/?r=1353905' target='blank' className="btn btn-info m-1 p-1" >FREE BITCOIN</a>
              <a href='https://t.me/solex_ai_bot?start=ODA4NzQ1NzQ4' target='blank' className="btn btn-info m-1 p-1" >SOLEX</a>
              <a href='https://t.me/major/start?startapp=808745748' target='blank' className="btn btn-info m-1 p-1" >MAJOR</a>
              <a href='https://t.me/tapcoinsbot/app?startapp=ref_S82478' target='blank' className="btn btn-info m-1 p-1" >TAPCOIN</a>
              <a href='https://t.me/bwcwukong_bot/Play?startapp=808745748' target='blank' className="btn btn-info m-1 p-1" >BLACK MYTH</a>
              <a href='https://t.me/CatDrivingBitcoinBot/CBD_Mini?startapp=53566010736810022' target='blank' className="btn btn-info m-1 p-1" >CAT DRIVING BITCOIN $CDB</a>
              <a href='https://t.me/Secondlive_bot/app?startapp=151bt4jw' target='blank' className="btn btn-info m-1 p-1" >CAT DRIVING BITCOIN $CDB</a>
              <a href='https://t.me/gemzcoin_Bot/tap?startapp=sjPiG-UO4tydY4XbHcg2nfy' target='blank' className="btn btn-info m-1 p-1" >GEMZ COIN</a>
              <a href='https://t.me/gamee/start?startapp=ref_808745748' target='blank' className="btn btn-info m-1 p-1" >GAMEE</a>
              <a href='https://t.me/vertus_app_bot/app?startapp=808745748' target='blank' className="btn btn-info m-1 p-1" >VERTUS</a>
              <a href='https://t.me/superjcybs' target='blank' className="btn btn-info m-1 p-1" >empty</a>
              <a href='https://t.me/superjcybs' target='blank' className="btn btn-info m-1 p-1" >empty</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__info">
              <h5>FREE MINING ON TELEGRAM 3</h5>
              <a href='https://t.me/callfluent_bot/app?startapp=r_808745748' target='blank' className="btn btn-warning m-1 p-1">CALL FLUENT</a>
              <a href='https://t.me/Tonmarket_ai_bot/app?startapp=0000gg6s' target='blank' className="btn btn-warning m-1 p-1">TOMATOES</a>
              <a href='https://t.me/theYescoin_bot/Yescoin?startapp=FmSBcp' target='blank' className="btn btn-info m-1 p-1" >Yescoin ($YES)</a>
              <a href='https://t.me/notcoin_bot?start=rp_21118703' target='blank' className="btn btn-info m-1 p-1" >NOTCOIN</a>
              <a href='https://t.me/muskempire_bot/game?startapp=hero808745748' target='blank' className="btn btn-info m-1 p-1" >MUSK EMPIRE</a>
              <a href='https://t.me/realgoats_bot/run?startapp=d2652cc7-4a3d-45c2-8a18-20f786ff148a' target='blank' className="btn btn-info m-1 p-1" >GOATS</a>
              <a href='https://t.me/PigshouseBot?start=808745748' target='blank' className="btn btn-info m-1 p-1" >PIGSHOUSE</a>
              <a href='https://t.me/hexn_bot/app?startapp=c7a022b1-4453' target='blank' className="btn btn-info m-1 p-1" >HEXN.IO</a>
              <a href='https://t.me/duckscoop_bot/app?startapp=N6esKUK5Bf' target='blank' className="btn btn-info m-1 p-1" >DUCK</a>
              <a href='https://t.me/birdx2_bot/birdx?startapp=808745748' target='blank' className="btn btn-info m-1 p-1" >BIRDS</a>
              <a href='https://t.me/owlsonton_bot?start=808745748' target='blank' className="btn btn-info m-1 p-1" >OWLS</a>
              <a href='https://t.me/Frogs_HouseBot?start=808745748' target='blank' className="btn btn-info m-1 p-1" >FROGS</a>
              <a href='https://t.me/Frogmates_bot/frogmates?startapp=808745748' target='blank' className="btn btn-info m-1 p-1" >FROGMATE</a>
              <a href='https://t.me/OfficialBananaBot/banana?startapp=referral=KOU83O' target='blank' className="btn btn-info m-1 p-1" >BANANA</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__info">
              <h5>FREE MINING ON TELEGRAM 4</h5>
              <a href='https://t.me/cedex_tap_bot?start=1723655738210067' target='blank' className="btn btn-warning m-1 p-1">CEDEX TAP</a>
              <a href='https://t.me/cexio_tap_bot?start=1723654213613875' target='blank' className="btn btn-warning m-1 p-1">CEXIO POWER</a>
              <a href='https://t.me/hamstEr_kombat_bot/start?startapp=kentId808745748' target='blank' className="btn btn-warning m-1 p-1">HAMSTER KOMBAT</a>
              <a href='https://https://t.me/wcoin_tapbot?start=ODA4NzQ1NzQ4' target='blank' className="btn btn-warning m-1 p-1">W-COIN</a>
              <a href='https://t.me/Simple_Tap_Bot/app?startapp=1724068705963' target='blank' className="btn btn-info m-1 p-1" >SIMPLE COIN</a>
              <a href='https://t.me/tonparty_bot/party?startapp=ref_YaCd4Slw' target='blank' className="btn btn-info m-1 p-1" >TONPARTY</a>
              <a href='https://t.me/notgram_game_bot?start=r808745748' target='blank' className="btn btn-info m-1 p-1" >NOTGRAM</a>
              <a href='https://t.me/catsgang_bot/join?startapp=lepMnRUT6_ApY1gvkf_mK' target='blank' className="btn btn-info m-1 p-1" >CATS</a>
              <a href='https://t.me/token1win_bot/start?startapp=refId808745748' target='blank' className="btn btn-info m-1 p-1" >1WIN TOKEN</a>
              <a href='https://t.me/booms_io_bot/start?startapp=bro808745748' target='blank' className="btn btn-info m-1 p-1" >BOOMS</a>
              <a href='https://t.me/FreeDurovBot/app?startapp=ref_BObmNbdy' target='blank' className="btn btn-info m-1 p-1" >FREE DUROV</a>
              <a href='https://t.me/Pumpad_Bot/Lucky?startapp=51425488425491315' target='blank' className="btn btn-info m-1 p-1" >PUMPAD</a>
              <a href='https://t.me/Pumpad_Bot/Luck' target='blank' className="btn btn-info m-1 p-1" >SEED APP</a>
            </div>
          </article>
           <article className="course" onClick={() => window.open("/blog/post/join-the-over-wallet-team-today-for-free")}>
            <div className="course__image">
              <img src="Assets/images/earningways/sunwave.png" alt='sunwave-superjcybs' />
            </div>
            <div className="course__info">
              <h5>SUNWAVE </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal name SUPERJCYBS</p>
              <a href='https://sunwavestoken.com/@superjcybs' target='blank' className="btn btn-success mb-2">TRY</a><br />
            </div>
          </article> 
           <article className="course" onClick={() => window.open("/blog/post/join-the-over-wallet-team-today-for-free")}>
            <div className="course__image">
              <img src="Assets/images/earningways/sauces.png" alt='sauces-superjcybs' />
            </div>
            <div className="course__info">
              <h5>SAUCES </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal name SUPERJCYBS</p>
              <a href='https://sauces.world/@superjcybs' target='blank' className="btn btn-success mb-2">TRY</a><br />
            </div>
          </article> 
           <article className="course" onClick={() => window.open("/blog/post/join-the-over-wallet-team-today-for-free")}>
            <div className="course__image">
              <img src="Assets/images/earningways/sealsend.png" alt='sealsend-superjcybs' />
            </div>
            <div className="course__info">
              <h5>SEALSEND </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal name SUPERJCYBS</p>
              <a href='https://sealsend/@superjcybs' target='blank' className="btn btn-success mb-2">TRY</a><br />
            </div>
          </article> 

          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/athene.jpg" alt='athene network-superjcybs' />
            </div>
            <div className="course__info">
              <h5>ATHENE NETWORK</h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code 823474989b</p>
              <a href='https://play.google.com/store/apps/details?id=network.athene.app' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/join-the-free-athene-network-mining-today' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>

          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/sidra.png" alt='sidra-superjcybs' />
            </div>
            <div className="course__info">
              <h5>SIDRA COINS AIRDROP</h5>
              <p> You can choose to download an app or use the web version. For Team work purpose, ensure that you see or enter this referal code superjcybs</p>
              <a href='https://www.sidrachain.com/u/superjcybs' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/pmatic.jpg" alt='matic-superjcybs' />
            </div>
            <div className="course__info">
              <h5>OPENVERSE AIRDROP</h5>
              <p> This is a web application. NO APP DOWNLOAD</p>
              <a href='https://opensoci.com/i/NR6JDWHZ' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
            </div>
          </article>

          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/azcoiner.jpg" alt='matic-superjcybs' />
            </div>
            <div className="course__info">
              <h5>AZ COINER AIRDROP</h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code superj</p>
              <a href='https://azcoiner.com/invite?user=superj' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/mine-azcoiner-for-free-with-superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/emc.jpeg" alt='matic-superjcybs' />
            </div>
            <div className="course__info">
              <h5>ETHER MAIL DISTRIBUTION</h5>
              <p> Be a part of the first email service on the blockchain. Receive mails like any other, completely encrypted</p>
              <a href='https://ethermail.io/?afid=6501b950d153180b4747ae6b' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/earn-free-250emc-in-the-new-ethermail' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/ripple.png" alt='xrp-superjcybs' />
            </div>
            <div className="course__info">
              <h5>XRP(RIPPLE) SPIN & WIN</h5>
              <p> You get to chance to spin and win XRP (ripple) and withdraw instantly instantly o. NO APP DOWNLOAD</p>
              <a href='https://xrpspin.com/6032065877263950?s=wt' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/earn-xrp-in-spin-and-win-with-superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          {/* <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/vigor3.jpg" alt='vigotoken-superjcybs' />
            </div>
            <div className="course__info">
              <h5>VIGOR TOKEN MINING</h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code 69417</p>
              <a href='https://t.co/HbkyhYDLem' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/mine-vigor-token-for-free-with-superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article> */}
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/pi2.jpg" alt='pi-superjcybs' />
            </div>
            <div className="course__info">
              <h5>PI MINING</h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code superjcybs</p>
              <a href='https://minepi.com/superjcybs' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/mine-pi-for-free-with-superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/atoshi.png" alt='matic-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREE ATOS TOKEN MINING</h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code XG34NA<br />
                You can actually withdraw 5 USDT which is exclusively for new members.
              </p>
              <a href='https://invite.atoshi.org/?code=XG34NA' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/mine-atoshi-token-for-free' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/satoshi.png" alt='satoshi-superjcybs' />
            </div>
            <div className="course__info">
              <h5>SATOSHI MINE</h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>69417</b><br />
              </p>
              <a href='https://www.btcs.fan/invite/8glia' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://www.btcs.fan/invite/8glia' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/cometm.jpg" alt='comet-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREE CO-MET COINS MINING </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>"superj"</b><br />
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.comet.network' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/mine-comet-token-for-free-with-superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/buffalo.jpg" alt='buffalo-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREE BUFFALO COINS MINING </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code 104745<br />
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.crypto.buffalonetwork' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='/blog/post/mine-buffalo-token-for-free-with-superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/bee.jpg" alt='bee-superjcybs' />
            </div>
            <div className="course__info">
              <h5>MINE BEE COINS FOR FREE </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code superjcybs<br />
              </p>
              <a href='https://j.bee.com/s?a=superjcybs' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://j.bee.com/s?a=superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>

          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/polygon.jpeg" alt='polygon-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREE POLYGON MINING </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>J0FIDL</b><br />
                Min. withdrawal is 30 polygons
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.polygon.matic.blockchain.aiserver' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://play.google.com/store/apps/details?id=com.polygon.matic.blockchain.aiserver' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/cpen.jpeg" alt='cpen-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREE cPEN MINING </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>superjcybs</b><br />

              </p>
              <a href='https://cpen.io?r=superjcybs' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://cpen.io?r=superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/blockrium.png" alt='blockrium-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREE BLOCKRIUM NETWORK MINING </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>y4Os4mJ03517</b><br />
                Get free mining and join a social DeFi network with Blockrium Network. Get Upto 100 BRC with the referal code
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.maticlegend.web3' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://play.google.com/store/apps/details?id=com.maticlegend.web3' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/imota.png" alt='imota-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREE IMOTA NETWORK MINING </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>dhVNmsSk</b><br />
                SPIN AND WIN
              </p>
              <a href='https://imota.io' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://imota.io' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/qtxai.jpeg" alt='qtxai-superjcybs' />
            </div>
            <div className="course__info">
              <h5>QTXAI TOKEN MINING
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>superjcybs</b><br />
              </p>
              <a href='https://download.qtxai.org/?referralcode=superjcybs' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://download.qtxai.org/?referralcode=superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/neptune.jpg" alt='neptune-superjcybs' />
            </div>
            <div className="course__info">
              <h5>NEPTUNE NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>muJtLS</b><br />
              </p>
              <a href='https://www.neptune.bet/' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog/post/claim-a-5000nt-welcome-bonus-on-neptune-network' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/xplus.jpg" alt='xplus-superjcybs' />
            </div>
            <div className="course__info">
              <h5>XPLUS NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>superjcybs</b><br />
              </p>
              <a href='https://onelink.to/33x6w4' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/bnp.jpeg" alt='bnp-superjcybs' />
            </div>
            <div className="course__info">
              <h5>BNP NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>abf52</b><br />
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.bnpnetwork.bnpapp' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          {/* <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/compad.jpeg" alt='neptune-superjcybs' />
            </div>
            <div className="course__info">
              <h5>COMPAD
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>b009c57b0ee2</b><br />
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.compad.app' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article> */}
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/marina.jpg" alt='neptune-superjcybs' />
            </div>
            <div className="course__info">
              <h5>MARINA NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>SUPERJCYBS</b><br />
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.marinaprotocol.app' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/xeno.png" alt='neptune-superjcybs' />
            </div>
            <div className="course__info">
              <h5>XENO NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>superjcybs</b><br />
              </p>
              <a href='https://xenonetwork.io/@superjcybs' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/dotchain.jpg" alt='neptune-superjcybs' />
            </div>
            <div className="course__info">
              <h5>DOTCHAIN NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>superjcybs</b><br />
              </p>
              <a href='https://dotchain.network/refer/superjcybs' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/opincur.jpg" alt='neptune-superjcybs' />
            </div>
            <div className="course__info">
              <h5>OPINCUR NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>superjcybs</b><br />
              </p>
              <a href='https://opincur.page.link/tjk5S8a2c1TWMeJi7' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/scai.png" alt='securechain-superjcybs' />
            </div>
            <div className="course__info">
              <h5>SECURECHAIN NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>1700841451</b><br />
              </p>
              <a href='https://securechain.ai/?ref=1700841451' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          {/* <article className="course" >
              <div className="course__image">
                <img src="Assets/images/earningways/blackfort.jpeg" alt='blackfort-superjcybs' />
              </div>
              <div className="course__info">
                <h5>BLACKFORT (BXN) NETWORK
                </h5>
                <p> You need to download an app. Distribution is on Fridays and for Team work purpose, ensure that you see or enter this referal code <b>muJtLS</b><br/>
                </p>
                <a href='https://www.neptune.bet/' target='blank' className="btn btn-success mb-2">Download</a><br/>
                <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
              </div>
            </article> */}
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/pse.jpg" alt='pse-superjcybs' />
            </div>
            <div className="course__info">
              <h5>PSE NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>1db3f</b><br />
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.psenetwork.pseapp' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          {/* <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/celia.jpg" alt='celia-superjcybs' />
            </div>
            <div className="course__info">
              <h5>CELIA NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>BHQKKCLT</b> and get 2 CLT<br />
              </p>
              <a href='https://celia.finance/register?referral_code=BHQKKCLT' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article> */}
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/buffai.webp" alt='buffaloai-superjcybs' />
            </div>
            <div className="course__info">
              <h5>BUFFALO APPFI
              </h5>
              <p> Mine USDT. You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>746687</b><br />
              </p>
              <a href='https://buffalonetworkearn.app/?kod=746687' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='https://play.google.com/store/apps/details?id=com.crypto.buffaloearn' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>

          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/zenix.webp" alt='zenix-superjcybs' />
            </div>
            <div className="course__info">
              <h5>ZENIX NETWORK
              </h5>
              <p> You need to download an app and for Team work purpose, ensure that you see or enter this referal code <b>superjcybs</b><br />
              </p>
              <a href='https://play.google.com/store/apps/details?id=com.zenixnetwork.cloudmineapp&hl=en&gl=US' target='blank' className="btn btn-success mb-2">Download</a><br />
              <a href='/blog' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>

        </div>
        <br />
        <a href="https://freebitco.in/?r=1353905" className='d-flex justify-content-center'>
          <img src="https://static1.freebitco.in/banners/728x90-3.png" style={{ width: '80vw' }} alt="get free bitcoin." />
        </a>
        <br />
        <h2 id='apps-n-tasks'>EARN FROM DOING TASKS (APPS & WEBSITES)</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/poppo.png" alt='poppo-superjcybs' />
            </div>
            <div className="course__info">
              <h5>POPPO LIVE</h5>
              <p> Make money for going LIVE. I have hundreds of testimonies from people i have recommended this product to. NO INVESTMENT NEEDED Try it<br /><b>Min. Withdraw:</b> $10<br /><b>Withdraw Method:</b> MoMo (MTN & VODAFONE), Crypto</p>
              <a href='https://h5-global.v.show/inviteNew/share?link_id=2669947' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://superjcybs.com/blog/post/getting-started-with-the-poppo-earning-app' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/surveyeah.png" alt='surveyeah-superjcybs' />
            </div>
            <div className="course__info">
              <h5>SURVEYEAH GHANA</h5>
              <p> Looking forward to be earning or making money on your phone? With this platform, you earn from responding to surveys. NO INVESTMENT NEEDED<br /><b>Min. Withdraw:</b> $5<br /><b>Withdraw Method:</b> Paypal</p>
              <a href='https://panel.surveyeah.com/en/p/4539887?s=share-link' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href=' https://panel.surveyeah.com/en/invitations/new#:~:text=https%3A//panel.surveyeah.com/en/p/4539887%3Fs%3Dshare%2Dlink' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/rewardy.png" alt='surveyeah-superjcybs' />
            </div>
            <div className="course__info">
              <h5>REWARDY</h5>
              <p>REWARDY is an app that allows you to make money from partaking in various micro tasks like app install, game play, surveys and signups. NO INVESTMENT NEEDED<br />
                <b>Min. Withdraw:</b> 2,000 points<br />
                <b>Withdraw Method:</b> Paypal, VISA, Crypto</p>
              <a href='https://rewardy.io/?ref=jerrybabatunde1' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://rewardy.io/?ref=jerrybabatunde1' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/freecash.png" alt='surveyeah-superjcybs' />
            </div>
            <div className="course__info">
              <h5>FREECASH WEB/APP</h5>
              <p>FREECASH is an web/app platform that allows you to make money from partaking in various micro tasks like app install, game play, surveys and signups. NO INVESTMENT NEEDED <br />
                <b>Min. Withdraw:</b> $5<br />
                <b>Withdraw Method:</b> Paypal, Bank, Crypto</p>
              <a href='https://freecash.com/r/d06dcb87fa' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://freecash.com/r/d06dcb87fa' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/paidwork.png" alt='surveyeah-superjcybs' />
            </div>
            <div className="course__info">
              <h5>PaidWork WEB/APP</h5>
              <p>PaidWork is an web/app platform that allows you to make money from partaking in various micro tasks like app install, game play, surveys and signups. NO INVESTMENT NEEDED <br />
                <b>Min. Withdraw:</b> $10<br />
                <b>Withdraw Method:</b> Paypal, Bank</p>
              <a href='https://www.paidwork.com/?r=jerrybabatunde1' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://www.paidwork.com/?r=jerrybabatunde1' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/earningways/mcrypto.png" alt='surveyeah-superjcybs' />
            </div>
            <div className="course__info">
              <h5>mCRYPTO APP</h5>
              <p>mCRYPTO is an app that allows you to make money from playing games for points<br />
                <b>Min. Withdraw:</b> $0.05 (500 points)<br />
                <b>Withdraw Method:</b> Crypto (USDT, BTC, ETH, BNN, Ripple)</p>
              <a href='https://mcrypto.info/?uid=gEF8Ll5kqn' target='blank' className="btn btn-success mb-2">REGISTER</a><br />
              <a href='https://mcrypto.info/?uid=gEF8Ll5kqn' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='referral-systems'>EARN FROM CLICKS/ADs (PTC, CPC)</h2>
        <p className='px-5 text-center pb-3 fw-bold'>This website pays you for bringing people unto their platform and also watching adverts.</p>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <a href='https://bit.ly/superjcybsonppl' target='blank'>
                <img src='./Assets/images/earningways/cashsuperstar.jpeg' alt='my certificate' />
              </a>
            </div>
            <div className="course__info">
              <h5>CASH SUPERSTAR</h5>
              <p>This website pays you for bringing people unto their platform.</p>
              <a href='https://bit.ly/superjcybsonppl' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://www.entireweb.com/?a=superjcybs' target='blank'>
                <img src='./Assets/images/earningways/entireweb.png' alt='entireweb-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>ENTIRE WEB</h5>
              <p>Do your usual online surfing or searches and get paid this time around. There isn't anyway you shouldn't be earning online nowadays.</p>
              <a href='https://www.entireweb.com/?a=superjcybs' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://vidiq.com/superjcybs' target='blank'>
                <img src='./Assets/images/earningways/vidiq.jpeg' alt='vidiq-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>VIDIQ VIDEO PROMOTION</h5>
              <p>This website pays you for bringing people unto their platform.</p>
              <a href='https://vidiq.com/superjcybs' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://cpalead.com/get-started.php?ref=1676688' target='blank'>
                <img src='./Assets/images/earningways/cpa.png' alt='cpa-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>CPA LEAD</h5>
              <p>I am a proud student of this college and I invite you to learn with them</p>
              <a href='https://cpalead.com/get-started.php?ref=1676688' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://www.cpagrip.com/signup.php?ref=405395' target='blank'>
                <img src='./Assets/images/earningways/cpagrid.png' alt='my certificate' />
              </a>
            </div>
            <div className="course__info">
              <h5>CPA GRID</h5>
              <p>start making profit from your online platform. Dont just use social media, use it for market(make money)</p>
              <a href='https://www.cpagrip.com/signup.php?ref=405395' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='crypto'>CRYPTOCURRENCY WALLETS</h2>
        <div className="container courses__container">
          <article className="course"  >
            <div className="course__image">
              <a href='https://www.binance.com/en/register?ref=169827774' target='blank'>
                <img src='./Assets/images/earningways/binance.png' alt='binance-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>BINANCE</h5>
              <p>This is my preferred crypto exchange.<br />
                You can hodl your assets and get interests on that far way beyond what your bank has ever given you.<br />
                You also have the opportunity to convert from fiat to crypto and vice versa.
              </p>
              <a href='https://www.binance.com/en/register?ref=169827774' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://bit.ly/OKXwithSUPERJCYBS' target='blank'>
                <img src='./Assets/images/earningways/okx.png' alt='okx-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>OKX</h5>
              <p>This is another crytowallet platform.
                OKX also gives you the opportunity to easily convert your fiat currencies into crypto and vice versa
              </p>
              <a href='https://bit.ly/OKXwithSUPERJCYBS' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://www.bitgetapp.com/referral/register?clacCode=XZ2QUSN2&from=%2Fevents%2Fturntable&source=events&utmSource=Luckydraw' target='blank'>
                <img src='./Assets/images/earningways/okx.png' alt='bitget-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>BITGET WALLET & ENCHANGE</h5>
              <p>This is another crytowallet platform.
                OKX also gives you the opportunity to easily convert your fiat currencies into crypto and vice versa
              </p>
              <a href='https://share.bwb.site/inviteLink?code=EwXcFy&shareLang=en&teamCode=&source=copy' target='blank' className="btn btn-primary">Wallet</a>
              <a href='https://www.bitgetapp.com/referral/register?clacCode=XZ2QUSN2&from=%2Fevents%2Fturntable&source=events&utmSource=Luckydraw' target='blank' className="btn btn-primary">Exchange</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://www.coinbase.com/join/love_bja' target='blank'>
                <img src='./Assets/images/earningways/coinbase.jpg' alt='coinbase-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>COINBASE</h5>
              <p>Looking for a place to store and trade crypto currencies. You can use coinbase also for that</p>
              <a href='https://www.coinbase.com/join/love_bja' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://share.trustwallet.com/gJtIgCd' target='blank'>
                <img src='./Assets/images/earningways/trustwallet.jpg' alt='trustwallet-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>TRUSTWALLET</h5>
              <p>This is a reliable top notch crypto wallet platform. It is actually widely used and has over the year lived to its name</p>
              <a href='https://share.trustwallet.com/gJtIgCd' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='supplements'>HEALTH SUPPLEMENTS</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <img src="Assets/images/courses/course1.jpg" alt='HTML - Web Development' />
            </div>
            <div className="course__info">
              <h5>LOOSING WEIGHT</h5>
              <p>I have hundreds of testimonies from people i have recommended this product to and their testimonies is the reason why i
                recommend this to you</p>
              <a href='http://bit.ly/3E5HgJr' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='learning'>LEARNING ONLINE</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <a href='http://bit.ly/3K3rLpd' target='blank'>
                <img src='./Assets/images/earningways/laimoon.png' alt='laimoon-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>LAIMOON</h5>
              <p>I am a proud student of this college and I invite you to learn with them</p>
              <a href='http://bit.ly/3K3rLpd' target='blank' className="btn btn-primary">Learn More</a>
            </div>

          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://bit.ly/3lJlODH' target='blank'>
                <img src='./Assets/images/earningways/alison.png' alt='alison-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>ALISON</h5>
              <p>I am a proud student of this college and I invite you to learn with them</p>
              <a href='https://bit.ly/3lJlODH' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://simpli-web.app.link/e/nkI8dOuKZob' target='blank'>
                <img src='./Assets/images/earningways/simplilearn.jpg' alt='simplilearn-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>SIMPLI LEARN</h5>
              <p>I am a proud student of this college and I invite you to learn with them</p>
              <a href='https://simpli-web.app.link/e/nkI8dOuKZob' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='affiliate-marketing'>AFFILIATE MARKETING & DROPSHIPPING</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <a href='http://perfectmoney.is/?ref=2163127' target='blank'>
                <img src='./Assets/images/earningways/amazon.png' alt='amazon-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>AMAZON</h5>
              <p>Shop everywhere in the world with one of the biggest online store in the world. You can even become their partner also and earn commission referring people to buy from amazon</p>
              <a href='http://perfectmoney.is/?ref=2163127' target='blank' className="btn btn-primary">Learn More</a>
            </div>

          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://bit.ly/3u38t9k' target='blank'>
                <img src='./Assets/images/earningways/clickbank.png' alt='clickbank-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>CLICKBANK</h5>
              <p>Don't just be on the internet, infact earn on the internet through affiliate marketing.</p>
              <a href='https://bit.ly/3u38t9k' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://dhgate.sjv.io/9WEo7E' target='blank'>
                <img src='./Assets/images/earningways/dhgate.png' alt='dhgate-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>DHGATE</h5>
              <p>Lets go shopping globally while earning commissions also</p>
              <a href='https://dhgate.sjv.io/9WEo7E' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='emoney'>ELECTRONIC MONEY (FINANCES)</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <a href='http://perfectmoney.is/?ref=2163127' target='blank'>
                <img src='./Assets/images/earningways/pm.png' alt='perfectMoney-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>PERFECT MONEY</h5>
              <p>Talking about using cryptocurrency to make every payment. Then register with perfect money today</p>
              <a href='http://perfectmoney.is/?ref=2163127' target='blank' className="btn btn-primary">Learn More</a>
            </div>

          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://dashboard.flutterwave.com/signup?referrals=RV149135' target='blank'>
                <img src='./Assets/images/earningways/flutterwave.jpg' alt='flutterwave-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>FLUTTERWAVE</h5>
              <p>Wondering how you can recieve and make payment to everywhere in africa, use this service. Join Now</p>
              <a href='https://dashboard.flutterwave.com/signup?referrals=RV149135' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='http://share.payoneer.com/nav/RC7uLjghaot2_BiC3Z_1ug2kFMDFS4Lz3y9JlgT2O8Hx2RCU2Y3OIAvO1ru9N0x0ivtumIm9r1O3zsr8NDJHyw2' target='blank'>
                <img src='./Assets/images/earningways/payoneer.png' alt='payoneer' />
              </a>
            </div>
            <div className="course__info">
              <h5>PAYONEER</h5>
              <p>With Payoneer you are able to receive payments or make payments from eveywhere in the world. You also get a USA and UK bank account to your name. Join Now</p>
              <a href='http://share.payoneer.com/nav/RC7uLjghaot2_BiC3Z_1ug2kFMDFS4Lz3y9JlgT2O8Hx2RCU2Y3OIAvO1ru9N0x0ivtumIm9r1O3zsr8NDJHyw2' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='network-marketing'>NETWORK MARKETING</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <a href='http://superj.swisderm.com/' target='blank'>
                <img src='./Assets/images/earningways/swisderm.jpg' alt='swisderm-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>SWISDERM HEALTH</h5>
              <p>If you have ever heard of stem cell products and know what is does, you will join this network marketing business right away to become a distributor for this product.</p>
              <a href='http://superj.swisderm.com/' target='blank' className="btn btn-primary">Register Now</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://wa.me/233247792110' target='blank'>
                <img src='./Assets/images/earningways/herbalife.jpg' alt='herbalife-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>HERBALIFE</h5>
              <p>Herbalife has been in the system for so long that it has become a household name. Every person who is concerned on good healthy life goes with Herbalife, you can become a distributor today</p>
              <a href='https://wa.me/233247792110' target='blank' className="btn btn-primary">Chat with Me</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://wa.me/233247792110' target='blank'>
                <img src='./Assets/images/earningways/longrich.png' alt='longrich-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>LONGRICH</h5>
              <p>The products from Longrich company is used by many, from toothpaste, sanitary pad, diapers, shoes etc. Every person who is concerned on good healthy life goes with Herbalife, you can become a distributor today</p>
              <a href='https://wa.me/233247792110' target='blank' className="btn btn-primary">Chat Me</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://yg.ucionorg.com/#/pages/user/register?recid=34357' target='blank'>
                <img src='./Assets/images/earningways/baixiaodan.jpg' alt='baixiaodan-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>BAIXIAODAN BUSINESS</h5>
              <p>Register for FREE, optionally choose to earn 5% on your investment of $10-$200 for a year in addition to the products you get. JOIN NOW</p>
              <a href='https://wa.me/233247792110?text=Hello+send+me+a+link+to+register+on+baixiaodan+thanks' target='blank' className="btn btn-primary mb-2">Register Now</a>
              <a href='/blog/post/baixiaodan-cf-income-proof-superjcybs' target='blank' className="btn btn-primary">Read More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='domain-n-hosting'>DOMAIN AND HOSTING</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <a href='https://namecheap.pxf.io/c/2755907/1130495/5618' target='blank'>
                <img src='./Assets/images/earningways/namecheap.jpg' alt='namecheap-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>NAMECHEAP</h5>
              <p>Namecheap is without doubt the most used webworks platform. From services ranging from Domain sales, Privacy, Hosting etc. You can even start your own firm with them</p>
              <a href='https://namecheap.pxf.io/GjmQvE' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='www.bluehost.com/track/superj' target='blank'>
                <img src='./Assets/images/earningways/bluehost.png' alt='bluehost-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>BLUEHOST</h5>
              <p>Bluehost is an alternative web hosting platform that you can engage with</p>
              <a href='www.bluehost.com/track/superj' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://www.interserver.net/r/517869' target='blank'>
                <img src='./Assets/images/earningways/interserver.png' alt='interserver-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>INTERSERVER</h5>
              <p>For your web hosting and domains, you can also use the services of interserver. Some uniqueness may catch your attention</p>
              <a href='https://www.interserver.net/r/517869' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
        <br />
        <br />
        <h2 id='eProducts'>E-PRODUCTS</h2>
        <div className="container courses__container">
          <article className="course" >
            <div className="course__image">
              <a href='https://www.renderforest.com/signup?afil_link=b151b3321aa8f8490a8973c39ede06f2' target='blank'>
                <img src='./Assets/images/earningways/renderforest.jpg' alt='renderforest-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>RENDERFOREST DESIGNS & TEMPLATES</h5>
              <p>If you are a designer either in graphics or web and looking forward to up your game then Renderforest has a complete suite for you
                get plugin, templates etc all at renderforest
              </p>
              <a href='https://www.renderforest.com/signup?afil_link=b151b3321aa8f8490a8973c39ede06f2' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://invideo.sjv.io/5bnd03' target='blank'>
                <img src='./Assets/images/earningways/invideo.png' alt='invideo-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>INVIDEO</h5>
              <p>Create stunning designs ranging from images, videos and animations all from your phone or PC<br />
                making it all beautiful<br />
                Choose invideo
              </p>
              <a href='https://invideo.sjv.io/5bnd03' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://partner.canva.com/qnyWjN' target='blank'>
                <img src='./Assets/images/earningways/canva.jpeg' alt='canva-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h5>CANVA</h5>
              <p>Get the space to create amazing designs like a pro. Whether its images, videos and animations all from your phone or PC<br />
                Subscribe to a canva pro<br /></p>
              <a href='https://partner.canva.com/qnyWjN' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
          <article className="course" >
            <div className="course__image">
              <a href='https://www.kqzyfj.com/click-100485482-14093397' target='blank'>
                <img src='./Assets/images/earningways/2game.png' alt='2game-superjcybs' />
              </a>
            </div>
            <div className="course__info">
              <h4>2GAME</h4>
              <p>Hello honorable gamer, in order to be able to be a firsthand game tester or download them, check out this website</p>
              <a href='https://www.kqzyfj.com/click-100485482-14093397' target='blank' className="btn btn-primary">Learn More</a>
            </div>
          </article>
        </div>
      </section>
    </>
  )
}

export default EarningWays
import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import './PostDetail.css'

import {
    FacebookShareButton, TelegramShareButton, WhatsappShareButton, LinkedinShareButton, TwitterShareButton, FacebookMessengerShareButton, 
     FacebookIcon, FacebookShareCount, TwitterIcon, WhatsappIcon, TelegramIcon, LinkedinIcon, FacebookMessengerIcon
} from 'react-share'



export const SocialShare = ({SelectedPost}) => {

    const facebookID = 686871576266039;
    const url = window.location.href;
    // const url = 'https://www.youtube.com/channel/UCnYL6e5pYht_mWKlEE7NTLw';
    return (
        <div className="text-center"> <i>share this post on &nbsp; </i>
            <FacebookShareButton url={url} quote={SelectedPost.title}>
                <FacebookShareCount url={url} /> <FacebookIcon size={32} round borderRadius={13} />
            </FacebookShareButton>
            <WhatsappShareButton url={url} title={SelectedPost.title} separator=":: " >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton url={url}>
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
         {/*   // <PinterestShareButton url={String(window.location)} media={`${String(window.location)}/${SelectedPost.featuredImage.url}`} >
            //     <PinterestIcon size={32} round />
            // </PinterestShareButton>
             <WorkplaceShareButton url={url} quote={SelectedPost.title} >
                <WorkplaceIcon size={32} round />
            </WorkplaceShareButton> 
            // <EmailShareButton url={url} subject={SelectedPost.title} body={SelectedPost.content.raw}  >
            //     <EmailIcon size={32} round />
            // </EmailShareButton>
            // <RedditShareButton url={url} title={SelectedPost.title} windowWidth={660} windowHeight={460}>
            //     <RedditIcon size={32} round />
            // </RedditShareButton>*/}
            <TelegramShareButton url={url} title={SelectedPost.title}>
                <TelegramIcon size={32} round />
            </TelegramShareButton>
            <TwitterShareButton url={url} title={SelectedPost.title}>
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <FacebookMessengerShareButton url={url} appId={facebookID} >
                <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
        </div>
    )
}


const PostDetail = ({ post }) => {
// console.log('received post', post)
    const getContentFragment = (index, text, obj, type) => {
        let modifiedText = text;
        if (obj) {
            if (obj.bold) {
                modifiedText = (<b key={index}>{text}</b>);
            }

            if (obj.italic) {
                modifiedText = (<em key={index}>{text}</em>);
            }

            if (obj.underline) {
                modifiedText = (<u key={index}>{text}</u>);
            }
            if (obj.type ==='link'){
                modifiedText = (<a style={{color:'blue'}} key={index} href={obj.href}>
                    {getContentFragment(0,obj.children[0].text, obj.children, obj.children.props)}</a>)
            }

        }

        switch (type) {
            case 'heading-three':
                return <h3 key={index} className="text-xl font-semibold mb-4">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h3>;
            case 'paragraph':
                return <p key={index} className="mb-8">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</p>;
            case 'heading-four':
                return <h4 key={index} className="text-md font-semibold mb-4">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h4>;
            case 'image':
                return (
                    <img
                        key={index}
                        alt={obj.title}
                        className='postdetails__image'
                        // height={obj.height}  ALREADY
                        // width={obj.width}    ALREADY
                        src={obj.src}
                    />
                );
                case 'link': 
                //ALREADY return <Link key={index} href={obj.nodeId}>{modifiedText.map((item,i)=><React.Fragment key={i}>{item}</React.Fragment>)}</Link> 
                return <Link key={index} href={obj.href}>{modifiedText.map((item,i)=><React.Fragment key={i}>{item}</React.Fragment>)}</Link>
            default:
                return modifiedText;
        }
    };

    return (
        <>
            <div id="postdetails">
                <div className="postdetails__featuredImage">
                    <img src={post.featuredImage.url}
                        alt={post.title} className="postdetails__image" />
                </div>
                <div className="postdetails__extras">
                    <div className="postdetails__inner">
                        <div className="postdetails__author">
                            <img
                                alt={post?.author.name}
                                height="30px"
                                width="30px"
                                style={{ borderRadius: '99%', verticalAlign: 'middle' }}
                                src={post?.author.photo.url}
                            />
                            <p>{post?.author.name}</p>
                        </div>
                        <div className="postdetails__timestamp">
                            <svg xmlns="http://www.w3.org/2000/svg" className="calenderIcon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <span style={{ verticalAlign: 'middle' }}>{moment(post.createdAt).format('MMM DD, YYYY')}</span>
                        </div>
                    </div>
                    <h1 style={{textAlign:'center'}}>{post.title}</h1>
<hr/>
                    <SocialShare SelectedPost={post}  />
                    <hr/>
                    {post.content.raw.children.map((typeObj, index) => {
                        const children = typeObj.children.map((item, itemindex) => getContentFragment(itemindex, item.text, item));
                        return getContentFragment(index, children, typeObj, typeObj.type);
                    })}


                </div>
            </div>
        </>
    )
}        
export default PostDetail
     

import React, { useEffect, useState } from 'react'
import { submitComment, publishComment, getComments } from '../../API/BlogStore';
import './CommentsForm.css'
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import Comments from './Comments';

const CommentsForm = ({slug}) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [comment, setComment] = useState('')
    const [save, setSave] = useState(true)
    
    const [commentList, setCommentList] = useState('')

  useEffect(() => {
    getComments(slug).then((result) => {
      setCommentList(result);
    });
  }, [slug]);

  const handlePostSubmission = async (e) => {
    e.preventDefault();
    setError(false);
    const commentObj = { name, email, comment, slug };
    if (!commentObj.name || !commentObj.email || !commentObj.comment) {
      setError(true);
      return;
    }

    if (save) {
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
    } else {
      localStorage.removeItem('name');
      localStorage.removeItem('email');
    }
setLoading(true)
     const { id } = await submitComment(commentObj)
         await publishComment(id)
        sendEmail()
        setLoading(false)
        setCommentList([...commentList, { id: id, name, email, comment }]);
        setName('');
        setEmail('');
        setComment('');
  };

        const sendEmail = (e) => {
        const templateParams = {
            user_name: name,
            user_email: email,
            comment: comment,
            type:"Comment",
            message: "Not Applicable",
            subject: "Not Applicable",
            platform: "GET INFORMED BLOG",
            slug: slug
        };
        emailjs
            .send('service_qxsvb5t', 'template_zgr4cxj', templateParams, { publicKey: 'ibZjaF1rjAsvzDVGf' })
            .then(
                (result) => {
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(null)
                    }, 5000);
                },
                (error) => {
                    setSuccess(false);
                    setTimeout(() => {
                        setSuccess(null)
                    }, 5000);
                });
    };

  return (
    <>
      <div className='commentsForm'>
          <h3>Leave a Comment</h3>
          <div className='commentsForm__controls'>
              <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" name="user_name" />
              <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" name="user_email" />
          </div>
          <div className='commentsForm__textarea'>
              <textarea name="comment" placeholder='Comment' cols="30" rows="10" value={comment} onChange={e => setComment(e.target.value)}></textarea>
          </div>
          <div className='commentsForm__sub'>
              <div>
                <input type="checkbox" checked={save} onChange={e=>setSave(e.target.value)} id='storeData' value="true"/>
                  <label style={{ display:'inline', marginLeft:'1rem', cursor: 'pointer', color: 'rgb(107,114,128)' }} htmlFor="storeData">Save my name, email in this browser for the next time I comment.</label>
               </div> 
          </div>
          {error && <p className='commentsForm__error'>All fields are mandatory</p>}
               {(success === true) ?
                    (<Alert variant='success'>Comment posted Successfully. Thank you!</Alert>) 
                    :
                    ((success === false)?(<Alert variant='danger'>Sorry, posting error, retry</Alert>)
                    :
                    (''))
                }
          <div style={{marginTop:'2rem'}}>
              <button disabled={loading} className="commentsForm__button"onClick={handlePostSubmission}>{loading ? 'Loading...' : 'Post Comment'}</button>
          </div>
    </div>
          <div>
          <Comments slug={slug} commentList={commentList}  />
          </div>
          </>
  )
}

export default CommentsForm